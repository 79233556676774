'use client';

import { getViemChain } from '@frontend/common';
import { optimism } from 'viem/chains';

const ALCHEMY_KEY = process.env.NEXT_PUBLIC_ALCHEMY_KEY;

if (!ALCHEMY_KEY) {
  throw new Error('NEXT_PUBLIC_ALCHEMY_KEY is not set');
}

export const ALCHEMY_RPCS = {
  1: `https://eth-mainnet.g.alchemy.com/v2/${ALCHEMY_KEY}`,
  10: optimism.rpcUrls.default.http[0], // Alchemy Optimism didn't work for some reason
  100: `https://gnosis-mainnet.g.alchemy.com/v2/${ALCHEMY_KEY}`,
  137: `https://polygon-mainnet.g.alchemy.com/v2/${ALCHEMY_KEY}`,
  8453: `https://base-mainnet.g.alchemy.com/v2/${ALCHEMY_KEY}`,
  42161: `https://arb-mainnet.g.alchemy.com/v2/${ALCHEMY_KEY}`,
  43114: `https://avalanche-c-chain.g.alchemy.com/v2/${ALCHEMY_KEY}`,
  56: `https://bnb-mainnet.g.alchemy.com/v2/${ALCHEMY_KEY}`,
};

export function defaultPublicRpc(chainId) {
  return alchemyRpcUrl(chainId) || wagmiDefautPublicRpcUrl(chainId);
}

export function alchemyRpcUrl(chainId: number) {
  return ALCHEMY_RPCS[chainId];
}

export function proxyRpcUrl(chainId: number) {
  return `/api/proxy/rpc?chainId=${chainId}`;
}

export function wagmiDefautPublicRpcUrl(chainId: number) {
  const chain = getViemChain(chainId);
  if (!chain) {
    return undefined;
  }
  return chain.rpcUrls.default.http[0];
}
