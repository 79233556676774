export type PreferenceSection = 'top2' | 'philanthropic' | 'defi' | 'all';

export const USD_STABLE_SYMBOLS = [
  'USDC',
  'USDT',
  'DAI',
  'USDGLO',
  'CRVUSD',
  'XDAI',
  'WXDAI',
  'USDM',
] as const;

export type USDStableSymbol = (typeof USD_STABLE_SYMBOLS)[number];

type PreferenceSections = Partial<
  Record<
    PreferenceSection,
    { name: string; symbols: USDStableSymbol[]; description?: string }
  >
>;

export const PREFERENCE_SECTIONS: PreferenceSections = {
  top2: {
    name: 'Top 2',
    symbols: ['USDC', 'USDT'],
    description: 'The two most popular stablecoins by market capitalization',
  },
  philanthropic: {
    name: 'Philanthropic',
    symbols: ['USDGLO'],
    description:
      'Stablecoins dedicated to charitable and philanthropic activities',
  },
  defi: {
    name: 'DeFi',
    symbols: ['DAI', 'CRVUSD', 'XDAI', 'USDM'],
    description:
      'Stablecoins commonly used in decentralized finance applications',
  },
};

export const DEFAULT_PREFERENCE_SECTIONS: PreferenceSections = {
  all: {
    name: 'All',
    symbols: Object.values(PREFERENCE_SECTIONS).flatMap(
      (section) => section?.symbols || [],
    ),
  },
};

export const ALL_PREFERENCE_SECTIONS: PreferenceSections = {
  ...DEFAULT_PREFERENCE_SECTIONS,
  ...PREFERENCE_SECTIONS,
};

// Utilities

export const isUSDStableSymbol = (symbol?: string): symbol is USDStableSymbol =>
  USD_STABLE_SYMBOLS.includes(symbol?.toUpperCase() as USDStableSymbol);
