'use client';

import { safeWindow } from './safeWindow';

export function yodlPaymasterUrl(): string {
  const customUrl = process.env.NEXT_PUBLIC_PAYMASTER_URL;
  if (customUrl) {
    return customUrl;
  }

  const baseUrl = safeWindow?.document.location.origin;
  if (baseUrl?.startsWith('http://')) {
    console.warn(
      'Paymasters may not work with HTTP. Use HTTPS in development.',
    );
  }

  return `${baseUrl}/api/paymaster`;
}
